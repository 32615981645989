export default {
	pink: '#FF0861',
	pinkTrans: 'rgba(255, 8, 97, 0.75)',
	pinkLight: '#FF4D8D',
	grey: '#4d4d4d',
	greyBlack: '#333333',
	blackish: '#1D1D1D',
	white: '#fff',
	whiteish: '#F9FAFB',

	green: '#35CE8D',
	red: '#DD614A',
};
