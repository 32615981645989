import React from 'react';
import styled from 'styled-components';

import ContentCard, { ContentCardProps } from './ContentCard/ContentCard';
import Card from './Card';
import Title from './Title';
import UserContent from './UserContent';
import ReadMore from './ReadMore';
import usePlaceholderImage from '../utils/hooks/usePlaceholderImage';

type NewsCardProps = ContentCardProps & {
	slug?: string;
};
const NewsCard: React.FC<NewsCardProps> = ({ slug, image, ...props }) => {
	const placeholder = usePlaceholderImage();
	const img = image || placeholder?.source_url;

	return <ContentCard link={`/news/${slug}`} image={img} {...props} />;
};

export default NewsCard;
