import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';
import { isExtLink } from '../../utils/helpers';

type Props = {
	className?: string;
	href?: string;
	onClick?: () => void;
};

const Wrapper = styled(Link)`
	display: flex;
	align-items: center;
	padding: 0;
	border: 0;
	text-decoration: none;
	font-size: ${({ theme }) => theme.fontSize.body};
	color: ${({ theme }) => theme.colors.text};
	background: none;

	svg {
		position: relative;
		height: 13px;
		top: -0.15rem;
		margin-left: 0.5rem;
	}
`;

const ReadMore: React.FC<Props> = ({ className, href, ...props }) => {
	let params: any = {};

	let as: string | typeof Link = 'button';

	if (href) {
		const isExt = isExtLink(href);
		if (isExt) {
			as = 'a';
			params.href = href;
		} else {
			as = Link;
			params.to = href;
		}
	} else {
		params = {
			...props,
		};
	}

	return (
		<Wrapper {...params} as={as} className={className}>
			Read more
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 448 512"
				fill={colors.pink}
			>
				<path d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z" />
			</svg>
		</Wrapper>
	);
};

export default ReadMore;
