import React from 'react';

const ContactCard = ({ title, subtitle, address, phone, email }) => {
	return (
		<div className="contact-location-div">
			{title && <h3 className="contact-location-title">{title}</h3>}
			{subtitle && (
				<h4
					className="contact-location-sub-title"
					dangerouslySetInnerHTML={{ __html: subtitle }}
				/>
			)}
			<div className="contact-location-details">
				{address && (
					<div className={'contact-location-details__item'}>
						<span className="icomoon icon-span-pink icomoon icon-mailbox-full" />
						<br />
						<span dangerouslySetInnerHTML={{ __html: address }} />
						<br />
					</div>
				)}

				{phone && (
					<div className={'contact-location-details__item'}>
						<span className="icomoon icon-span-pink icomoon icon-telephone" />
						<br />
						{phone}
						<br />
					</div>
				)}

				{email && (
					<div className={'contact-location-details__item'}>
						<span className="icomoon icon-span-pink icomoon icon-envelope" />
						<br />
						{email}
						<br />
					</div>
				)}
			</div>
		</div>
	);
};

export default ContactCard;
