import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { reveal as Menu } from 'react-burger-menu';

import { Footer, Navigation, QuickForm } from '../components';
import Context, { Provider } from '../components/Context';
import Modal from '../components/Modal';
import themes from '../styles/themes';
import GlobalStyles from '../styles/global';

import '../css/index.scss';

const Layout = ({ children, location }) => {
	return (
		<ThemeProvider theme={themes.light}>
			<GlobalStyles />
			<Provider>
				<Context.Consumer>
					{({ modal, setModal }) => (
						<Fragment>
							<Navigation location={location} />
							{children}
							<Navigation footer />

							<HiddenForms />

							<Modal open={modal} onClose={() => setModal(false)}>
								{typeof modal === 'string' ? (
									<div
										dangerouslySetInnerHTML={{
											__html: modal,
										}}
									/>
								) : (
									<QuickForm
										content={modal}
										// page={location.pathname}
										onSend={() => setModal(false)}
									/>
								)}
							</Modal>
						</Fragment>
					)}
				</Context.Consumer>
			</Provider>
		</ThemeProvider>
	);
};

const HiddenForms = () => (
	<Fragment>
		<form
			name={'contact'}
			data-netlify="true"
			style={{
				display: 'none',
			}}
		>
			<input type="text" name={'name'} />
			<input type="email" name={'email'} />
			<input type="text" name={'telephone'} />
			<input type="text" name={'business'} />
			<textarea name={'enquiry'} />
			<input type="checkbox" name={'acceptTerms'} />
		</form>
	</Fragment>
);

export default Layout;
