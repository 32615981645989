import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
	width: 100%;
`;

const FlipCard = ({ front, back, rounded, className }) => (
	<Card
		className={`card flip-card ${rounded ? 'flip-card--rounded' : ''} ${
			!back ? 'flip-card--no-flip' : ''
		} ${className}`}
	>
		<div className="flip-card__flipper">
			<div className="flip-card__front">{front()}</div>

			{back && <div className="flip-card__back">{back()}</div>}
		</div>

		{/*{ back && <div className="sr-only">{ back() }</div> }*/}
	</Card>
);

FlipCard.defaultProps = {
	className: '',
};

export default FlipCard;
