import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

type StaffMember = GatsbyTypes.AllStaffQuery['staff']['edges'][0];

const useStaff = () => {
	const { staff } = useStaticQuery<GatsbyTypes.AllStaffQuery>(graphql`
		query AllStaff {
			staff: allWordpressWpStaff {
				edges {
					node {
						title
						slug
						content
						wordpress_id
						featured_media {
							source_url
						}
						acf {
							role
							mentor_role
							intro
						}
					}
				}
			}
		}
	`);

	const findOne = ({
		slug,
		wordpress_id,
	}: {
		slug?: string;
		wordpress_id?: number;
	}): StaffMember | undefined => {
		return staff.edges.find(({ node }) => {
			if (slug) return node.slug === slug;
			if (wordpress_id) return node.wordpress_id === wordpress_id;
			return null;
		});
	};

	return {
		allStaff: staff.edges,
		findOne,
	} as const;
};

export default useStaff;
