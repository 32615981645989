import moment from 'moment';
import { isAfter, parseISO } from 'date-fns';

const splitDate = (date) => {
	let regex = /(\d{4})(\d{2})(\d{2})/,
		matches = regex.exec(date);
	return matches || [];
};

export const getDate = (date) => {
	let matches = splitDate(date);
	return matches[3] + '/' + matches[2] + '/' + matches[1];
};

export const getJSDate = (_date) => {
	let date = getDate(_date).split('/');
	let d = parseInt(date[0], 10),
		m = parseInt(date[1], 10),
		y = parseInt(date[2], 10);
	return new Date(y, m - 1, d);
};

export const getTime = (start, end) => {
	let regex = /(\d{2}):(\d{2}):(\d{2})/,
		startMatches = regex.exec(start),
		endMatches = regex.exec(end);

	if (!startMatches || !startMatches.length) return '';

	let ret = startMatches[1] + ':' + startMatches[2];

	if (!!end && !!endMatches)
		ret += ' to ' + endMatches[1] + ':' + endMatches[2];
	return ret;
};

export const getTimeStamp = (date, shortFinalDate) => {
	let post_date = moment.utc(date),
		now = moment(),
		seconds_passed = now.diff(post_date, 'seconds'),
		minutes_passed = now.diff(post_date, 'minutes');

	if (seconds_passed < 20) {
		return ' just now';
	} else if (seconds_passed < 60) {
		return seconds_passed.toFixed(0) + ' seconds ago';
	} else if (minutes_passed < 60) {
		return minutes_passed.toFixed(0) + ' minutes ago';
	} else if (minutes_passed < 90) {
		return 'Just over an hour ago';
	} else if (minutes_passed < 60 * 24) {
		return (minutes_passed / 60).toFixed(0) + ' hours ago';
	} else if (minutes_passed < 60 * 24 * 2) {
		return 'Yesterday';
	} else if (minutes_passed < 60 * 24 * 5) {
		return (minutes_passed / 60 / 24).toFixed(0) + ' days ago';
	} else {
		return shortFinalDate ? this.shortDate(date) : this.longDate(date);
	}
};

export const getFormattedDate = () => {
	return moment().format('D/M/YYYY HH:mm:ss');
};

export const makeISOString = ({ date, end_time }) => {
	let isoString = date.split('/').reverse().join('-');
	isoString += end_time ? `T${end_time}` : `T00:00:00`;

	return isoString;
};

export const filterOldEvents = (data) => {
	const { date, end_time } = 'node' in data ? data.node.acf : data.acf;

	let isoString = makeISOString({ date, end_time });

	return isAfter(parseISO(isoString), new Date());
};
