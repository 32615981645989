import { createGlobalStyle } from 'styled-components';
import breakpoints from './breakpoints';

export default createGlobalStyle`
	@font-face {
		font-family: "Founders Grotesk";
		src: url("/fonts/FoundersGrotesk/FoundersGrotesk-Light.woff2")
				format("woff2"),
			url("/fonts/FoundersGrotesk/FoundersGrotesk-Light.woff")
				format("woff");
		font-weight: 300;
		font-style: normal;
		font-display: block;
	}

	@font-face {
		font-family: "Founders Grotesk";
		src: url("/fonts/FoundersGrotesk/FoundersGrotesk-Semibold.woff2")
				format("woff2"),
			url("/fonts/FoundersGrotesk/FoundersGrotesk-Semibold.woff")
				format("woff");
		font-weight: 600;
		font-style: normal;
		font-display: block;
	}

	@font-face {
		font-family: "Founders Grotesk";
		src: url("/fonts/FoundersGrotesk/FoundersGrotesk-Regular.woff2")
				format("woff2"),
			url("/fonts/FoundersGrotesk/FoundersGrotesk-Regular.woff")
				format("woff");
		font-weight: normal;
		font-style: normal;
		font-display: block;
	}

	@font-face {
		font-family: "Founders Grotesk";
		src: url("/fonts/FoundersGrotesk/FoundersGrotesk-Bold.woff2")
				format("woff2"),
			url("/fonts/FoundersGrotesk/FoundersGrotesk-Bold.woff")
				format("woff");
		font-weight: bold;
		font-style: normal;
		font-display: block;
	}

	html {
		font-size: 16px;
	}

	body {
		font-family: ${({ theme }) => theme.font.primary};
		font-weight: ${({ theme }) => theme.fontWeight.light};
		color: ${({ theme }) => theme.colors.text};
		line-height: 1.5;

    &.ReactModal__Body--open {
      overflow: hidden;
    }
	}

	h1,h2,h3,h4,h5,h6,p {
		margin: 0;
	}

	p {
		font-size: 1.125rem;
	}

	svg {
		display: block;
	}

	.headroom-wrapper {
		position: relative;
		z-index: 3;

		~* {
			z-index: 1;
		}
	}

	.ReactModal__Overlay {
		display: grid;
		place-content: center;
    overflow: auto;
	}

	.ReactModal__Content {
		overflow: auto;
    max-height: 100%;

		&:focus {
			outline: 0;
		}
	}

	@media ${breakpoints.mobileLand} {
		html {
			font-size: 14px;
		}
	}
`;
