import styled from 'styled-components';

type Props = {
	size?: 'sm' | 'md' | 'lg';
};

const Container = styled.div<Props>`
	width: 100%;
	max-width: ${({ size }) => {
		if (size === 'lg') return '1330px';
		if (size === 'sm') return '975px';
		return '1160px';
	}};
	margin-right: auto;
	margin-left: auto;
	padding-right: 15px;
	padding-left: 15px;
`;

export default Container;
