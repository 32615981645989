import React, { Fragment } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
// import { graphql } from 'react-apollo';

import settings from '../config/site';
// import { getFooterMenuQuery } from '../queries/menu'
import { ContactForm } from '.';
import styled from 'styled-components';

const menu = [...settings.menu].reverse();
menu.push({
	url: '/privacy-policy',
	text: 'Privacy Policy',
});

const Wrapper = styled.footer`
	background: ${({ theme }) => theme.colors.primary};
`;

const Footer = () => {
	let { menus } = useStaticQuery(graphql`
		{
			menus: allWordpressWpApiMenusMenusItems(
				filter: { slug: { eq: "footer-menu" } }
			) {
				edges {
					node {
						name
						items {
							title
							object_slug
							type
						}
					}
				}
			}
		}
	`);

	return (
		<footer className={'footer'}>
			<div className="footer-section">
				<div className="footer-container w-container">
					<div className="footer-row w-row">
						<div className="footer-row-col1 w-col w-col-2 w-col-small-small-stack w-col-tiny-tiny-stack w-col-stack">
							<Link to={'/'}>
								<img
									src="/images/TownSq-landscape-logo-white.png"
									width={147}
									alt={'TownSq logo'}
									className="image-9"
								/>
							</Link>
						</div>

						<div className="footer__menu footer-row-col2 w-clearfix w-col w-col-10 w-col-small-small-stack w-col-tiny-tiny-stack w-col-stack">
							{menus.edges[0].node.items.map(
								({
									object_slug,
									title,
									type_label,
									target,
									classes,
									wordpress_children,
								}) => {
									if (url && url.source_url) {
										return (
											<a
												href={url.source_url}
												className="nav-link footer-link"
												key={`footer${title}`}
												target={'_blank'}
											>
												{title}
											</a>
										);
									} else {
										return (
											<Link
												to={`/${object_slug}`}
												className="nav-link footer-link"
												key={`footer${title}`}
											>
												{title}
											</Link>
										);
									}
								}
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="contact-section">
				<div className="contact-container w-container">
					<h2 className="heading-7">
						If you're interested in joining TownSq, or learning more - get in
						touch
					</h2>
					<ContactForm />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
