import React from 'react';
import ReactModal from 'react-modal';

export type ModalProps = {
	className?: string;
	open?: boolean;
	onClose: () => void;
	title?: string;
	content?: React.ReactNode;
};

ReactModal.setAppElement('#___gatsby');

const Modal: React.FC<ModalProps> = ({
	children,
	className,
	content,
	onClose,
	open,
	title,
}) => {
	return (
		<ReactModal
			className={className}
			isOpen={!!open}
			onRequestClose={onClose}
			style={{
				overlay: {
					backgroundColor: 'rgba(0, 0, 0, 0.7)',
					zIndex: 9999,
				},
				content: {
					position: 'absolute',
					top: '50%',
					right: 'initial',
					bottom: 'initial',
					left: '50%',
					transform: 'translateY(-50%) translateX(-50%)',
					padding: 0,
					border: 0,
					background: 'none',
				},
			}}
		>
			<div className="pop-up">
				<button onClick={onClose} className="pop-up__close">
					<img src="/images/Cross.svg" width={22} alt="" className="close" />
					<span className="sr-only">Close modal</span>
				</button>
				{(title || content) && (
					<div className="modal-content">
						{title && <div className="modal-title">{title}</div>}
						{content && <div className="modal-text">{content}</div>}
					</div>
				)}
				{children}
			</div>
		</ReactModal>
	);
};

export default Modal;
