import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Title from './Title';
import usePlaceholderImage from '../utils/hooks/usePlaceholderImage';

const Wrapper = styled.div`
	display: flex;
	margin-bottom: ${({ theme }) => theme.spacing.sectionPaddingSm};

	.news-preview__img {
		width: 60%;
		padding-right: 12px;

		+ * {
			padding-left: 12px;
		}
	}

	a {
		text-decoration: none;
	}
`;

const NewsArticleBig = ({ title, excerpt, image, slug, acf }) => {
	const placeholder = usePlaceholderImage();

	let img = acf.header_image || placeholder;

	return (
		<Wrapper className={'news-preview news-preview--lg'}>
			<div className="news-preview__img">
				<Link to={'/news/' + slug}>
					{img && (
						<img
							src={img.source_url}
							alt={acf.header_image ? acf.header_image.alt_text : title}
						/>
					)}
				</Link>
			</div>

			<div className="news-preview__content">
				<Link to={'/news/' + slug}>
					<Title as="p" size="sm" dangerouslySetInnerHTML={{ __html: title }} />
				</Link>
				<div
					className="news-paragraph"
					dangerouslySetInnerHTML={{ __html: excerpt }}
				/>
			</div>
			<div className="w-clearfix" />
		</Wrapper>
	);
};

export default NewsArticleBig;
