import styled from 'styled-components';
import { motion } from 'framer-motion';
import breakpoints from '../../styles/breakpoints';

type Props = {
	color?: 'normal' | 'inverted' | 'dark';
	weight?: 'normal' | 'light';
	letters?: 'normal' | 'condensed';
	size?: 'xs' | 'sm' | 'md' | 'lg';
	underline?: boolean;
};

const Title = styled(motion.h1)<Props>`
	margin-bottom: ${({ theme, size }) => {
		if (size === 'xs') return theme.spacing[4];
		if (size === 'sm') return theme.spacing[6];
		if (size === 'md') return theme.spacing[6];
		return theme.spacing[10];
	}};
	color: ${({ theme, color }) => {
		if (color === 'dark') return theme.colors.text;
		if (color === 'inverted') return theme.colors.textOnPrimary;
		return theme.colors.primary;
	}};
	font-size: ${({ theme, size }) => {
		if (size === 'xs') return theme.fontSize.bodyLg;
		if (size === 'sm') return theme.fontSize.titleSm;
		if (size === 'lg') return theme.fontSize.titleLg;
		return theme.fontSize.title;
	}};
	font-weight: ${({ theme, weight }) => {
		if (weight === 'light') return theme.fontWeight.light;
		return theme.fontWeight.normal;
	}};
	line-height: ${({ theme, size }) => {
		if (size === 'lg') return 1.5;
		return 1.25;
	}};
	letter-spacing: ${({ letters }) => {
		if (letters === 'condensed') return '-1.35px';
		return 'initial';
	}};
	text-decoration: none;

	${({ theme, underline }) =>
		underline &&
		`
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 4px;
      background: ${theme.colors.primary};
    }
  `}

	@media ${breakpoints.mobilePort} {
		font-size: ${({ theme, size }) => {
			if (size === 'xs') return theme.fontSize.bodyLg;
			if (size === 'sm') return theme.fontSize.titleSm;
			// if (size === "lg") return theme.fontSize.titleLg;
			return theme.fontSize.title;
		}};
	}
`;

export default Title;
