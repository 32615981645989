import React, { useEffect } from 'react';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

type Colors = 'inverted' | 'normal';

type Props = {
	className?: string;
	content?: string;
	path?: string;
	color?: Colors;
	openModal?: () => void;
};

const Content = styled.div<{ color?: Colors }>`
	font-size: ${({ theme }) => theme.fontSize.bodyLg};
	color: ${({ theme, color }) => {
		if (color === 'inverted') return theme.colors.textOnPrimary;
		return theme.colors.text;
	}};

	* {
		color: inherit;
		font-size: inherit;
	}

	h4 {
		font-weight: 500;
	}

	p {
		margin: 0;

		+ * {
			margin-top: 20px;
		}
	}

	strong {
		font-weight: 500;
	}

	img {
		height: auto;
	}

	li {
		//font-size: 16px;
		font-weight: 300;
	}

	&.central {
		text-align: center;
	}

	@media ${breakpoints.mobileLand} {
		img {
			~ i {
				display: block;
				font-size: 14px;
				text-emphasis: left;
			}
		}
	}
`;

const UserContent: React.FC<Props> = ({
	className,
	content,
	path,
	color,
	openModal,
}) => {
	const handleClick = (e: MouseEvent) => {
		if (!openModal) return;

		if (
			// @ts-ignore
			e?.target?.href === '#' ||
			e?.target?.href?.includes(path + '#')
		) {
			e.preventDefault();

			openModal();
		}
	};

	useEffect(() => {
		if (typeof window === 'undefined') return;
		window.addEventListener('click', handleClick);

		return () => {
			window.removeEventListener('click', handleClick);
		};
	}, []);

	if (!content) return null;

	return (
		<Content
			className={className}
			color={color}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	);
};

export default UserContent;
