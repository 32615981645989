const live = 'https://www.thetownsquare.co.uk';

export const mediaUrl = 'https://cms.thetownsquare.co.uk/wp-content/uploads/';
export const serverUrl =
	process.env.NODE_ENV === 'production' ? live : 'http://localhost:25000';
export const graphQLUrl = serverUrl + '/graphql';
export const apiUrl = serverUrl + '/api';
export const url =
	process.env.NODE_ENV === 'production' ? live : 'http://localhost:3001';

export default {
	mediaUrl,
	serverUrl,
	graphQLUrl,
	url,
	apiUrl,
	leadCaptureUrl: 'https://hooks.zapier.com/hooks/catch/693527/sckc54/',
	menu: [
		{
			text: 'Spaces',
			url: '/spaces',
		},
		{
			text: 'Clubs',
			url: '/clubs',
		},
		{
			text: 'Accelerator',
			url: '/accelerator',
		},
		{
			text: 'News',
			url: '/news',
		},
		{
			text: 'Events',
			url: '/events',
		},
		{
			text: 'Partners',
			url: '/partners',
		},
		{
			text: 'About',
			url: '/about-us',
		},
		{
			text: 'Contact',
			url: '/contact-us',
		},
	],
};
