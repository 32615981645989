import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import Container from './Container';
import Title from './Title/Title';
import breakpoints from '../styles/breakpoints';

type Colors = 'grey';

type Props = {
	className?: string;
	title: string;
	titleAs?: any;
	color?: Colors;
	content?: React.ReactNode;
	titleColor?: 'inverted' | 'normal';
	padTop?: boolean;
	beforeContainer?: React.ReactNode;
	containerSize?: 'sm' | 'md' | 'lg';
	style?: object;
};

const Wrapper = styled.div<{ padTop?: boolean; color?: Colors }>`
	position: relative;
	padding-top: ${({ theme, padTop }) => padTop && theme.spacing.sectionPadding};
	padding-bottom: ${({ theme }) => theme.spacing.sectionPadding};
	background: ${({ color }) => color && '#f8f7f7'};

	.section-title {
		margin-bottom: ${({ theme }) => theme.spacing[12]};
		text-align: center;
	}

	@media ${breakpoints.mobileLand} {
		padding-top: ${({ theme, padTop }) =>
			padTop && theme.spacing.sectionPaddingMobile};
		padding-bottom: ${({ theme }) => theme.spacing.sectionPaddingMobile};

		.section-title {
			margin-bottom: ${({ theme }) => theme.spacing[8]};
		}
	}
`;

const TitleSection: React.FC<Props> = ({
	className,
	title,
	titleAs = 'h2',
	content,
	color,
	titleColor,
	padTop = true,
	containerSize = 'md',
	beforeContainer,
	style,
}) => (
	<Wrapper className={className} padTop={padTop} style={style} color={color}>
		{beforeContainer}
		<Container size={containerSize}>
			{title && (
				<Fade>
					<Title color={titleColor} as={titleAs} className="section-title">
						{title}
					</Title>
				</Fade>
			)}
			{content}
		</Container>
	</Wrapper>
);

export default TitleSection;
