import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

type Props = {};

const ThreeCol = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: ${({ theme }) => `calc(-1 * (${theme.spacing.gutter} / 2))`};
	margin-right: ${({ theme }) => `calc(-1 * (${theme.spacing.gutter} / 2))`};
	margin-left: ${({ theme }) => `calc(-1 * (${theme.spacing.gutter} / 2))`};

	> * {
		width: ${({ theme }) =>
			`calc(((100% - (3 * ${theme.spacing.gutter})) / 3))`};
		margin: ${({ theme }) => `calc(${theme.spacing.gutter} / 2)`};
	}

	.react-reveal {
		display: flex;
		justify-content: center;
		> * {
			width: 100%;
		}
	}

	@media ${breakpoints.mobileLand} {
		> * {
			width: ${({ theme }) => `calc(50%  - (${theme.spacing.gutter} * 2))`};
		}
	}

	@media ${breakpoints.mobilePort} {
		> * {
			width: 100%;
		}
	}
`;

export default ThreeCol;
