exports.shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	const currentPosition = getSavedScrollPosition(location);
	const queriedPosition = getSavedScrollPosition({ pathname: `/articles` });

	if (location.pathname.includes('clubs/articles')) {
		return false;
	}

	return true;
};
