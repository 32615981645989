import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Title from './Title';
import styled from 'styled-components';

const Wrapper = styled.div`
	text-align: center;

	.secondary-container {
		padding: ${({ theme }) => `${theme.spacing[14]} ${theme.spacing[8]} 0`};
		z-index: 2;
	}

	.grey-box-p {
		font-size: ${({ theme }) => theme.fontSize.bodyXl};

		* {
			font-size: inherit;
		}
	}
`;

const CentralTextBlock = ({ className = '', title, text, lgTitle, after }) => {
	return (
		<Wrapper className={`secondary-section ctb ${className}`}>
			<div className="secondary-container w-container">
				<Fade>
					<div className="grey-box-wrapper">
						{title && (
							<Title
								as="h2"
								color="inverted"
								dangerouslySetInnerHTML={{
									__html: title,
								}}
							/>
						)}
						{text && (
							<div
								className="grey-box-p"
								dangerouslySetInnerHTML={{
									__html: text,
								}}
							/>
						)}
						{after}
					</div>
				</Fade>
			</div>
		</Wrapper>
	);
};

CentralTextBlock.defaultProps = {
	lgTitle: false,
};

export default CentralTextBlock;
