import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Context from './Context';

const settings = {
	slidesToShow: 1,
	// infinite: true,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 4000,
	speed: 500,
	fade: false,
	cssEase: 'linear',
};

const Hero = ({
	image,
	images,
	title,
	text,
	link,
	linkText,
	linkPopup,
	logo,
	short,
}) => {
	if (!images) return null;
	return (
		<Context.Consumer>
			{({ setModal }) => (
				<Slider {...settings}>
					{images.map(({ image, focus }) => (
						<div key={`image${image.source_url}`}>
							<div
								style={{
									backgroundImage: `url(${image.source_url})`,
									backgroundPosition: focus,
									backgroundAttachment: 'scroll',
								}}
								className={`hero-section home-page-hero ${
									short ? 'hero-section--short' : ''
								}`}
							>
								<div className="hero-container w-container">
									{title && (
										<h1
											className={`fade-down hero-h1-uc ${
												logo ? 'sr-only' : ''
											}`}
										>
											{title}
										</h1>
									)}

									{logo && (
										<div className="fade-down _5-to-9-logo-wrapper">
											<img src={logo} alt={title} />
										</div>
									)}

									{text && <h1 className="fade-down hero-h1">{text}</h1>}

									{linkText ? (
										<div className="button-wrapper">
											{!linkPopup ? (
												<Link to={link} className="button-2 w-button">
													{linkText}
												</Link>
											) : (
												<button
													onClick={() => setModal(true)}
													className={'button-2 w-button'}
												>
													{linkText}
												</button>
											)}
										</div>
									) : null}
								</div>
							</div>
						</div>
					))}
				</Slider>
			)}
		</Context.Consumer>
	);
};

export default Hero;
