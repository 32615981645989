import { DefaultTheme } from 'styled-components';
import { darken, lighten } from 'polished';

import colors from './colors';

const themes: {
	light?: DefaultTheme;
	dark?: DefaultTheme;
} = {
	light: {
		borderRadius: {
			card: '10px',
			xs: '3px',
			sm: '5px',
			md: '10px',
			lg: '16px',
		},
		colors: {
			danger: colors.red,
			primary: colors.pink,
			primaryLight: colors.pinkLight,
			primaryLighter: lighten(0.1, colors.pinkLight),
			primaryDark: darken(0.1, colors.pink),
			primaryTrans: colors.pinkTrans,
			success: colors.green,
			text: colors.greyBlack,
			textFaded: colors.grey,
			textOnPrimary: colors.white,
			blackish: colors.blackish,
			grey: '#ddd',
			white: colors.white,
			whiteish: colors.whiteish,
		},
		font: {
			primary: "'Founders Grotesk', sans-serif",
			// primaryMedium: "sansMedium",
			// primaryBold: "sansBold",
			// primaryAlt: "sansAlt",
		},
		fontSize: {
			body: '1rem',
			bodyLg: '1.125rem',
			bodyXl: '1.25rem',
			title: '2.125rem',
			titleSm: '1.5rem',
			titleLg: '2.5rem',
		},
		fontWeight: {
			light: 300,
			normal: 400,
			semiBold: 600,
			bold: 700,
		},
		spacing: {
			px: '1px',
			'1': '0.25rem',
			'2': '0.5rem',
			'3': '0.75rem',
			'4': '1rem',
			'5': '1.25rem',
			'6': '1.5rem',
			'7': '1.75rem',
			'8': '2rem',
			'9': '2.25rem',
			'10': '2.5rem',
			'11': '2.75rem',
			'12': '3rem',
			'13': '3.25rem',
			'14': '3.5rem',
			'15': '3.75rem',
			'16': '4rem',
			'17': '4.25rem',
			'18': '4.5rem',
			'20': '5rem',
			'24': '6rem',
			'32': '8rem',
			'40': '10rem',
			'48': '12rem',
			'56': '14rem',
			'64': '16rem',
			padding: '1.5rem',
			sectionPadding: '7rem',
			sectionPaddingSm: '5.25rem',
			sectionPaddingMobile: '4rem',
			gutter: '30px',
		},
	},
};

export default themes;
