import React, { Component } from 'react';

import site from '../../config/site';

const encode = (data) =>
	Object.keys(data)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
		.join('&');

class ContactForm extends Component {
	constructor() {
		super();

		this.state = {
			form: {
				name: '',
				email: '',
				telephone: '',
				business: '',
				notes: '',
				receive_marketing: false,
				honeypot: '',
			},
			sent: false,
			sending: false,
			error: '',
		};
	}

	formSent = () => {
		this.setState({
			sent: true,
			sending: false,
		});
	};

	formNotSent = (error) => {
		this.setState({
			error,
			sent: false,
			sending: false,
		});
	};

	handleChange = (e, id) => {
		this.setState({
			form: {
				...this.state.form,
				[id]: e.target.value,
			},
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();

		this.setState({ sending: true });

		let { form } = this.state;

		if (form.honeypot) return false;

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'contact', ...form }),
		})
			.then(this.formSent)
			.catch(this.formNotSent);

		return false;
	};

	render() {
		let {
			sent,
			error,
			form: {
				name,
				email,
				telephone,
				business,
				notes,
				receive_marketing,
				honeypot,
			},
		} = this.state;

		const formValid = name && notes && (email || telephone);

		return (
			<div className="form-block w-form">
				{!sent ? (
					<form onSubmit={this.handleSubmit} className="form">
						<div className="form-row w-row">
							<div className="form-row-col1 w-col w-col-6">
								<label htmlFor="name" className="field-label">
									Name:*
								</label>
								<input
									type="text"
									className="text-field-3 w-input"
									value={name}
									onChange={(e) => this.handleChange(e, 'name')}
									id={'name'}
									required
								/>

								<label htmlFor="email" className="field-label">
									Email Address:*
								</label>
								<input
									type="email"
									className="text-field-5 w-input"
									value={email}
									onChange={(e) => this.handleChange(e, 'email')}
									id={'email'}
									required
								/>

								<label htmlFor="telephone" className="field-label">
									Phone Number:
								</label>
								<input
									type="text"
									className="text-field-5 w-input"
									value={telephone}
									onChange={(e) => this.handleChange(e, 'telephone')}
									id={'telephone'}
									required
								/>
							</div>
							<div className="form-row-col2 w-clearfix w-col w-col-6">
								<label htmlFor="business-name" className="field-label">
									Your Business:
								</label>
								<input
									type="text"
									className="text-field-4 w-input"
									value={business}
									id={'business'}
									onChange={(e) => this.handleChange(e, 'business')}
								/>

								<label htmlFor="message" className="field-label">
									Message:*
								</label>
								<textarea
									value={notes}
									onChange={(e) => this.handleChange(e, 'notes')}
									className="textarea w-input"
									id={'message'}
									required
								/>

								<div className="checkbox-field w-checkbox">
									<input
										type="checkbox"
										value={receive_marketing}
										id="Marketing checkbox"
										className="w-checkbox-input"
									/>
									<label
										htmlFor="Marketing checkbox"
										className="checkbox-label w-form-label"
									>
										I would like to receive marketing from TownSq and agree to
										the{' '}
										<a
											href={
												site.mediaUrl + '/2018/05/Privacy-policy-010518-1.pdf'
											}
											className="link"
											target={'_blank'}
										>
											terms and conditions and privacy policy.
										</a>
									</label>
								</div>

								<input
									type="hidden"
									value={honeypot}
									onChange={(e) => this.handleChange(e, 'honeypot')}
								/>

								<input
									type="submit"
									defaultValue="Submit"
									className="submit-button-2 w-button"
									disabled={!formValid || this.state.sending}
								/>
							</div>

							{error && (
								<div className="w-col w-col-12 form__error">
									<p>{error.message}</p>
								</div>
							)}
						</div>
					</form>
				) : (
					<div className="w-form-done">
						<div>Thank you! Your submission has been received!</div>
					</div>
				)}
			</div>
		);
	}
}

export default ContactForm;
