import React, { Component, useEffect, useState } from 'react';
import { StaticQuery, graphql, Link, useStaticQuery } from 'gatsby';
import Headroom from 'react-headroom';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';

import Container from '../Container';
import Btn from '../Button';
import Socials from '../Socials';
import breakpoints from '../../styles/breakpoints';

type Props = {
	footer?: boolean;
};

const Button = styled(Btn)``;

type MenuProps = {
	isFooter: boolean;
};
const Menu = styled.nav<MenuProps>`
	display: flex;
	align-items: center;

	a {
		color: ${({ theme }) => theme.colors.textOnPrimary};
		font-size: ${({ theme }) => theme.fontSize.body};
		text-decoration: none;

		&:hover {
			:not(:last-of-type) {
				color: ${({ theme }) => theme.colors.grey};
			}
		}

		+ * {
			margin-left: ${({ theme }) => theme.spacing[9]};
		}
	}

	@media (max-width: 991px) {
		${Button} {
			display: grid;
			place-content: center;
			height: 42px;
			padding: 0;
			margin-right: 0;
			border: 0;
			background: transparent;
		}
	}

	@media (max-width: 899px) {
		display: ${({ isFooter }) => (isFooter ? 'flex' : 'none')};
	}
`;

const MobMenu = styled.div<{ open: boolean }>`
	nav {
		display: flex;
		flex-direction: column;
		position: fixed;
		top: 100px;
		left: 0;
		transform: translateY(${({ open }) => (open ? 0 : '-100%')});
		transition: transform 0.3s ease-in-out;
		width: 100%;
		z-index: 0;
		background: ${({ theme }) => theme.colors.blackish};

		a {
			width: 100%;
			padding: ${({ theme }) => theme.spacing[4]};
			background: ${({ theme }) => theme.colors.blackish};
			color: ${({ theme }) => theme.colors.textOnPrimary};
			text-decoration: none;
			font-size: ${({ theme }) => theme.fontSize.bodyXl};
			text-align: center;
		}

		${Button} {
			width: 100%;
			height: auto;

			&:hover {
				background: ${({ theme }) => theme.colors.blackish};
			}
		}
	}
`;

const footerStyles = css`
	@media ${breakpoints.tablet} {
		flex-direction: column;
		height: auto;
		padding-top: ${({ theme }) => theme.spacing[10]};
		padding-bottom: ${({ theme }) => theme.spacing[10]};

		nav {
			margin: ${({ theme }) => theme.spacing[7]} 0;
		}
	}

	@media ${breakpoints.mobileLand} {
		nav {
			flex-direction: column;

			a {
				+ * {
					margin: ${({ theme }) => theme.spacing[3]} 0 0 0;
				}
			}
		}
	}
`;

// TODO: Move over responsive menu from SCSS
const Wrapper = styled.header`
	position: relative;
	background: ${({ theme }) => theme.colors.primary};
	z-index: 3;

	@media (max-width: 991px) {
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: ${({ theme }) => theme.colors.primary};
			z-index: 1;
		}

		> div:first-of-type > *:not(.header__menu):not(${MobMenu}) {
			z-index: 2;
		}
	}
`;

const Inner = styled(Container)<{ footer?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100px;

	${({ footer }) => footer && footerStyles};
`;

const Logo = styled.img`
	height: 49px;
`;

const Hamburger = styled.button<{ open: boolean }>`
	padding: ${({ theme }) => theme.spacing[4]};
	margin-left: auto;
	margin-right: -${({ theme }) => theme.spacing[4]};
	background: none;
	cursor: pointer;
	border: 0;

	.inner {
		position: relative;
		width: 28px;
		height: 24px;
	}

	span {
		position: absolute;
		left: 0;
		width: 100%;
		height: 4px;
		border-radius: 2px;
		opacity: 1;
		background: ${({ theme }) => theme.colors.white};
		transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out,
			top 0.2s ease-in-out;

		&:nth-of-type(1) {
			top: ${(props) => (props.open ? '9px' : 0)};
			transform: ${(props) => (props.open ? 'rotate(45deg)' : 'none')};
		}
		&:nth-of-type(2) {
			top: 10px;
			width: ${(props) => (props.open ? 0 : '100%')};
			opacity: ${(props) => (props.open ? 0 : 1)};
		}
		&:nth-of-type(3) {
			top: ${(props) => (props.open ? '9px' : '20px')};
			transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'none')};
		}
	}

	&:focus {
		outline: 1px solid ${({ theme }) => theme.colors.textOnPrimary};
	}
`;

const Navigation: React.FC<Props> = ({ footer }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const showMobileMenu = useMediaQuery({ query: '(max-width: 899px)' });

	const windowType = typeof window;
	const location = typeof window !== 'undefined' ? window.location.href : '';

	useEffect(() => {
		setMenuOpen(false);
	}, [location]);

	const { menus, footerMenu } = useStaticQuery<GatsbyTypes.MenuQuery>(graphql`
		query Menu {
			menus: allWordpressWpApiMenusMenusItems(
				filter: { slug: { eq: "main-navigation" } }
			) {
				edges {
					node {
						name
						items {
							title
							object_slug
							type
							url {
								source_url
							}
						}
					}
				}
			}
			footerMenu: allWordpressWpApiMenusMenusItems(
				filter: { slug: { eq: "footer-menu" } }
			) {
				edges {
					node {
						name
						items {
							title
							object_slug
							type
							url {
								source_url
							}
						}
					}
				}
			}
		}
	`);

	const renderMenuItems = () => {
		const menu = footer
			? footerMenu?.edges?.[0]?.node
			: menus?.edges?.[0]?.node;

		if (!menu) return null;

		return menu?.items?.map((item) => {
			if (item?.object_slug === 'contact-us') {
				if (footer) return null;
				return (
					<>
						<Button
							rounded
							alt
							href={`/${item?.object_slug}`}
							key={item?.object_slug}
						>
							{item?.title}
						</Button>

						<a
							href={'https://members.townsq.co.uk'}
							target="https://members.townsq.co.uk"
							style={{ fontWeight: 600 }}
						>
							Log in | Register
						</a>
					</>
				);
			}

			if (item?.type === 'custom') {
				let url = item?.url?.source_url;
				if(item?.object_slug === 'business-support' && !url) {
					url = 'https://www.townsq.support'
				}
				if(item?.object_slug === 'admin-support' && !url) {
					url = 'https://www.townsqadmin.co.uk'
				}
				return (
					<a href={url} target="_blank">
						{item?.title}
					</a>
				);
			}

			return (
				<Link to={`/${item?.object_slug}`} key={item?.object_slug}>
					{item?.title}
				</Link>
			);
		});
	};

	const renderNav = (isMobile: boolean) => (
		<Wrapper as={footer ? 'footer' : 'header'}>
			<Inner footer={footer} size={footer ? 'md' : 'lg'}>
				<Link to={'/'} className="w-nav-brand w--current">
					<Logo
						src="/images/TownSq-landscape-logo-white.png"
						alt={'TownSq logo'}
					/>
				</Link>

				{isMobile && !footer ? (
					<>
						<Hamburger
							open={menuOpen}
							onClick={() => setMenuOpen((prev) => !prev)}
						>
							<div className="inner">
								<span />
								<span />
								<span />
							</div>
						</Hamburger>

						<MobMenu open={menuOpen} key="mmenu">
							<nav>{renderMenuItems()}</nav>
						</MobMenu>
					</>
				) : (
					<Menu isFooter={!!footer} key="menu">
						{renderMenuItems()}
					</Menu>
				)}

				{footer && <Socials />}
			</Inner>
		</Wrapper>
	);

	if (footer) {
		return renderNav(false);
	}

	return (
		<Headroom
			onUnpin={() => {
				setMenuOpen(false);
			}}
			style={{ zIndex: 100, position: 'fixed' }}
		>
			{renderNav(showMobileMenu)}
		</Headroom>
	);
};

export default Navigation;
