import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.div`
	.color-block__title {
		+ * {
			margin-top: ${({ theme }) => theme.spacing[6]};
		}
	}

	p {
		width: 100%;
		font-size: 0.95rem;
		line-height: 1.1;
		color: ${({ theme }) => theme.colors.textOnPrimary};

		+ p {
			margin-top: 0;
		}
	}
`;

const ColorBlock = ({
	title,
	text,
	subtext,
	color,
	image,
	url,
	className,
	onClick,
}) => {
	let style = image ? { backgroundImage: `url(${image})` } : {};

	const inner = (
		<div className="color-block__content">
			<h3 className="color-block__title">{title}</h3>
			{text && <p dangerouslySetInnerHTML={{ __html: text }} />}
			{subtext && <p dangerouslySetInnerHTML={{ __html: subtext }} />}
		</div>
	);

	const isExtLink = (url) => {
		return url.includes('http');
	};

	return (
		<Wrapper className="accelerator-location-card">
			<div
				className={`color-block ${
					image ? 'color-block--img' : ''
				} ${className}`}
				style={style}
			>
				{url ? (
					isExtLink(url) ? (
						<a href={url} target={'_blank'}>
							{inner}
						</a>
					) : (
						<Link to={url}>{inner}</Link>
					)
				) : onClick ? (
					<button onClick={onClick}>{inner}</button>
				) : (
					inner
				)}
			</div>
		</Wrapper>
	);
};

ColorBlock.defaultProps = {
	className: '',
};

export default ColorBlock;
