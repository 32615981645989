import React from 'react';
import styled from 'styled-components';

import Card from '../Card';
import Title from '../Title';
import UserContent from '../UserContent';
import ReadMore from '../ReadMore';
import { Maybe } from '../../../graphql-types';

export type ContentCardProps = {
	central?: boolean;
	className?: string;
	image?: string;
	title?: string;
	text?: string;
	link?: string;
	linkText?: string;
	underlineTitle?: boolean;
	date?: Maybe<string>;
	readTime?: number;
	hideReadMore?: boolean;
};

const ContentCard: React.FC<ContentCardProps> = ({
	central,
	className,
	image,
	title,
	text,
	link,
	underlineTitle,
	date,
	readTime,
	hideReadMore,
}) => (
	<Wrapper central={central} className={className}>
		{image && (
			<div className="news-card__img">
				<img src={image} alt={title} />
			</div>
		)}
		<div className="news-card__inner news-card-content w-clearfix">
			{title && (
				<Title
					underline={underlineTitle}
					as="p"
					size="xs"
					dangerouslySetInnerHTML={{
						__html: title,
					}}
				/>
			)}
			<Content content={text} />

			{date && (
				<FooterContainer>
					<FooterText color="dark" size="xs">
						Posted {date} | {readTime} min read
					</FooterText>
				</FooterContainer>
			)}
			{link && !hideReadMore && <Button central={central} href={link} />}
		</div>
	</Wrapper>
);

ContentCard.defaultProps = {
	linkText: 'More info',
	link: '#',
};

type ButtonProps = Pick<ContentCardProps, 'central'>;

const FooterContainer = styled.div`
	display: flex;
`;

const FooterText = styled(Title)`
	font-size: ${(props) => props.theme.fontSize.body}; ;
`;

const Button = styled(ReadMore)<ButtonProps>`
	align-self: ${({ central }) => (central ? 'center' : 'flex-end')};
	margin-top: auto;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

type WrapperProps = Pick<ContentCardProps, 'central'>;
const Wrapper = styled(Card)<WrapperProps>`
	position: relative;
	// Fixes safari hover overflow bug
	transform: translateZ(0);
	text-align: ${({ central }) => (central ? 'center' : 'left')};

	${Title} {
		text-align: inherit;
		margin-right: ${({ central }) => (central ? 'auto' : 'initial')};
		margin-left: ${({ central }) => (central ? 'auto' : 'initial')};
	}

	.news-card__img {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 60%;
		overflow: hidden;

		img {
			display: block;
			transition: transform 0.2s, box-shadow 0.2s;
		}
	}

	.news-card__inner {
		flex: 1;
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding: ${({ theme }) => theme.spacing[6]};
		padding-top: ${({ theme }) => theme.spacing[7]};
		background: ${({ theme }) => theme.colors.white};
	}

	&:hover {
		img {
			transform: scale(1.025);
		}
	}
`;

const Content = styled(UserContent)`
	position: relative;
	z-index: 1;
	margin-bottom: ${({ theme }) => theme.spacing[6]};
	text-overflow: ellipsis;
	/* max-height: 100px;
	overflow: hidden; */
`;

export default ContentCard;
