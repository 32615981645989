import styled from 'styled-components';

const Card = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	max-width: none;
	border-radius: ${({ theme }) => theme.borderRadius.card};
	overflow: hidden;
	transition: transform 0.2s, box-shadow 0.2s;
	box-shadow: 2px 2px 14px 2px rgba(0, 0, 0, 0.15);

	&:hover {
		box-shadow: 2px 2px 30px 2px rgba(0, 0, 0, 0.15);
	}
`;

export default Card;
