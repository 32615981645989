import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const usePlaceholderImage = () => {
	const { options } = useStaticQuery<GatsbyTypes.PlaceholderQuery>(graphql`
		query Placeholder {
			options: wordpressAcfOptions {
				options {
					placeholder_image {
						source_url
						alt_text
					}
				}
			}
		}
	`);

	return options?.options?.placeholder_image;
};

export default usePlaceholderImage;
