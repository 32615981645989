import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
// Component
import { Context, FlipCard } from '.';
import Btn from './Button';
import FadeIn from './FadeIn';
import PersonCard from './PersonCard';
import ThreeCol from './ThreeCol';
// Hooks
import useStaff from '../utils/hooks/useStaff';

const Button = styled(Btn)``;

const Wrapper = styled.div`
	${Button} {
		margin: ${({ theme }) => theme.spacing[6]} auto 0;
	}
`;

type TeamBlockProps = {
	isMentor?: boolean;
	title: string;
	team: Array<{
		wordpress_id: number;
	}>;
};

const TeamBlock: React.FC<TeamBlockProps> = ({ title, team, isMentor }) => {
	const { findOne } = useStaff();

	const staff = team.map((item) =>
		findOne({ wordpress_id: item.wordpress_id })
	);
	// console.log(staff);

	return (
		<Context.Consumer>
			{({ setModal }) => {
				return (
					<Wrapper className="team-wrapper">
						<>
							{title && <h2 className="team-h2">{title}</h2>}

							{!!staff.length ? (
								<ThreeCol>
									{staff.map(
										({ node: { title, content, featured_media, acf } }) => {
											return (
												<FadeIn key={title}>
													<PersonCard
														name={title}
														image={{
															source_url: featured_media
																? featured_media.source_url
																: '',
															alt_text: title,
														}}
														role={
															isMentor ? acf.mentor_role || acf.role : acf.role
														}
														bio={content}
													/>
												</FadeIn>
											);
										}
									)}
								</ThreeCol>
							) : null}
						</>
					</Wrapper>
				);
			}}
		</Context.Consumer>
	);
};

export default TeamBlock;
