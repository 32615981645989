import React, { Component, Fragment } from 'react';
import { navigate, StaticQuery, graphql } from 'gatsby';
import axios from 'axios';

import { getFormattedDate } from '../utils/dateFormatting';

import site from '../config/site';
import Title from './Title';
import styled from 'styled-components';
import Btn from './Button';

const Button = styled(Btn)`
	margin: 0 auto;
`;

const Intro = styled.div`
	${Title} {
		text-align: center;
		margin-bottom: ${({ theme }) => theme.spacing[4]};
	}
`;

class QuickForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				name: '',
				email: '',
				phone: '',
				enquiry: this.props.content.space
					? 'I would like to book a tour at ' + this.props.content.space
					: '',
				honeypot: '',
			},
			sent: false,
			sending: false,
			error: '',
		};
	}

	formSent = (state) => {
		this.props.onSend();

		navigate('/lead', { state });
	};

	formNotSent = (error) => {
		error = typeof error === 'string' ? error : error.message;

		this.setState({
			error,
			sent: false,
			sending: false,
		});
	};

	handleChange = (e, id) => {
		this.setState({
			form: {
				...this.state.form,
				[id]: e.target.value,
			},
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();

		this.setState({ sending: true });

		let {
			form: { name, email, phone, enquiry, honeypot },
		} = this.state;

		if (honeypot) return false;

		const date = getFormattedDate();

		const params = {
			name,
			email,
			phone,
			notes: enquiry + ` <enquiry sent at ${date}>`,
			createdAt: date,
			csrf: 'o87sbo7aodb7woa7bd9a7o9w',
		};

		// console.log({ params });

		axios
			.get(site.leadCaptureUrl, {
				params,
			})
			.then(() => this.formSent(params))
			.catch(this.formNotSent);

		return false;
	};

	render() {
		let {
			sent,
			error,
			form: { name, email, phone, enquiry, honeypot },
		} = this.state;

		const formValid = name && email && enquiry;
		//   const { options } = useStaticQuery<GatsbyTypes.SocialsQuery>(graphql`

		// `);

		const description = this.props.content.space
			? "If you're interested in booking a space at " +
			  this.props.content.space +
			  ', let us know and we will be in touch.'
			: '';

		// console.log({ form: this.state.form });

		return (
			<StaticQuery
				query={graphql`
					query Derp {
						options: wordpressAcfOptions {
							options {
								contact_form_title
								contact_form_text
							}
						}
					}
				`}
				render={(data) => {
					// console.log('daaa', data);
					return (
						<Fragment>
							<Intro className="pop-up-intro">
								<Title as="h4" size="xs">
									{this.props.content.title
										? this.props.content.title
										: 'Book a coffee'}
								</Title>
								<p className="pop-up-p">
									{this.props.content.space
										? description
										: 'If you’re interested in learning more about how we can support you to grow your idea, let us know and we will be in touch.'}
								</p>
							</Intro>

							{sent ? (
								<div className="success-message-2 w-form-done w-form-done--white">
									<div className="text-block-3">
										<i className="icomoon icon-checkmark-circle" />
									</div>
									<div className="text-block-4">
										Thank you! We'll be in touch.
									</div>
								</div>
							) : (
								<div className="form-block-2 w-form">
									<form onSubmit={this.handleSubmit}>
										<div className="pop-up-form-row w-row">
											<div className="pop-up-form-row-col1 w-col w-col-1 w-col-small-1 w-col-tiny-1">
												<div className="icomoon icon icomoon icon-user" />
											</div>
											<div className="pop-up-form-row-col2 w-col w-col-11 w-col-small-11 w-col-tiny-11">
												<input
													value={name}
													onChange={(e) => this.handleChange(e, 'name')}
													type="text"
													className="name-field1 w-input"
													placeholder="Name*"
													required
												/>
											</div>
										</div>
										<div className="pop-up-form-row w-row">
											<div className="pop-up-form-row-col1 w-col w-col-1 w-col-small-1 w-col-tiny-1">
												<div className="icomoon icon icomoon icon-envelope" />
											</div>
											<div className="pop-up-form-row-col2 w-col w-col-11 w-col-small-11 w-col-tiny-11">
												<input
													value={email}
													onChange={(e) => this.handleChange(e, 'email')}
													type="email"
													className="email-field-1 w-input"
													placeholder="Email*"
													required
												/>
											</div>
										</div>
										<div className="pop-up-form-row w-row">
											<div className="pop-up-form-row-col1 w-col w-col-1 w-col-medium-1 w-col-small-1 w-col-tiny-1">
												<div className="icomoon icon icomoon icon-telephone" />
											</div>
											<div className="pop-up-form-row-col2 w-col w-col-11 w-col-medium-11 w-col-small-11 w-col-tiny-11">
												<input
													value={phone}
													onChange={(e) => this.handleChange(e, 'phone')}
													type="text"
													className="phone-field-1 w-input"
													placeholder="Phone Number"
												/>
											</div>
										</div>
										{this.props.content.space ? (
											<></>
										) : (
											<div className="pop-up-form-row-message w-row">
												<div className="pop-up-form-row-message-col1 w-col w-col-1 w-col-small-1 w-col-tiny-1">
													<div className="icomoon icon-message icomoon icon-document" />
												</div>
												<div className="pop-up-form-row-message-col2 w-col w-col-11 w-col-small-11 w-col-tiny-11">
													<textarea
														value={enquiry}
														onChange={(e) => this.handleChange(e, 'enquiry')}
														placeholder="How can we help you?"
														className="message-field-1 w-input"
														required={true}
													/>
												</div>
											</div>
										)}

										<input
											type="hidden"
											value={honeypot}
											onChange={(e) => this.handleChange(e, 'honeypot')}
										/>

										<Button
											type="submit"
											disabled={!formValid || this.state.sending}
										>
											Send
										</Button>
									</form>

									{error && (
										<div
											className="w-form-fail"
											style={{
												marginLeft: '15px',
												marginRight: '15px',
											}}
										>
											<div className="text-block-5">{error}</div>
										</div>
									)}
								</div>
							)}
						</Fragment>
					);
				}}
			/>
		);
	}
}

export default QuickForm;
