import React, { useState } from 'react';

type Props = {
	modal?: string | boolean;
	setModal: (content: string) => void;
};

const Context = React.createContext<Props>({
	modal: undefined,
	setModal: () => null,
});

const Provider: React.FC = ({ children }) => {
	let [modal, setModal] = useState<string | boolean>();

	return (
		<Context.Provider
			value={{
				modal,
				setModal,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default Context;

export { Provider };
