import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { isExtLink } from '../../utils/helpers';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	className?: string;
	alt?: boolean;
	rounded?: boolean;
	href?: string;
};

const ButtonStyles = styled.button<{ rounded?: boolean; alt?: boolean }>`
	display: grid;
	place-items: center;
	height: 42px;
	align-items: center;
	padding-right: ${({ theme }) => theme.spacing[4]};
	padding-left: ${({ theme }) => theme.spacing[4]};
	border-radius: ${({ rounded }) => rounded && '4px'};
	background-color: ${({ theme, alt }) => {
		if (alt) return theme.colors.primaryLight;
		return theme.colors.primary;
	}};
	color: ${({ theme }) => theme.colors.textOnPrimary};
	font-size: ${({ theme }) => theme.fontSize.body};
	text-decoration: none;
	transition: background-color 0.2s;

	span {
		position: relative;
		top: 0.075rem;
	}

	&:hover:not(:disabled) {
		background-color: ${({ theme, alt }) => {
			if (alt) return theme.colors.primaryLighter;
			return theme.colors.primaryLight;
		}};
		color: ${({ theme }) => theme.colors.textOnPrimary};
	}

	&:disabled {
		opacity: 0.5;
	}
`;

// TODO: Check center on different devices
const Button: React.FC<Props> = ({
	children,
	className,
	href,
	alt,
	rounded,
	...props
}) => {
	let params: any = {};

	let as: string | typeof Link = 'button';

	if (href) {
		const isExt = isExtLink(href);
		if (isExt) {
			as = 'a';
			params.href = href;
			params.target = '_blank';
			params.rel = 'noreferrer noopener';
		} else {
			as = Link;
			params.to = href;
		}
	} else {
		params = {
			...props,
		};
	}

	return (
		<ButtonStyles
			alt={alt}
			as={as}
			rounded={rounded}
			className={className}
			{...params}
		>
			<span>{children}</span>
		</ButtonStyles>
	);
};

export default Button;
