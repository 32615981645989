import React from 'react';
import { Link } from 'gatsby';
import { Fade } from 'react-awesome-reveal';
import { getDate, getTime } from '../utils/dateFormatting';
import Title from './Title';
import styled from 'styled-components';

const EventRow = styled.div`
	a {
		text-decoration: none;
	}
`;

const Event = ({
	title,
	excerpt,
	slug,
	date,
	start_time,
	end_time,
	selected,
}) => {
	let formattedDate = date;
	let formattedTime = getTime(start_time, end_time);
	return (
		<EventRow
			className={'event-row' + (selected ? ' event-row--selected' : '')}
		>
			<Fade>
				<Link to={'/event/' + slug}>
					<Title
						as="h4"
						size="sm"
						dangerouslySetInnerHTML={{
							__html: title,
						}}
						className="mtop0"
					/>
				</Link>
				<div
					className="blurb"
					dangerouslySetInnerHTML={{
						__html: excerpt,
					}}
				/>
				<p>
					<strong>Date:</strong> {formattedDate}
				</p>
				<p>
					<strong>Time:</strong> {formattedTime}
				</p>
			</Fade>
		</EventRow>
	);
};

export default Event;
