import React from 'react';
import handleViewport from 'react-in-viewport';
import styled from 'styled-components';

type FadeInProps = {
	enterCount: number;
	forwardedRef: any;
	inViewport: boolean;
};

const FadeIn: React.FC<FadeInProps> = ({
	children,
	enterCount,
	inViewport,
	forwardedRef,
	...props
}) => {
	return (
		<Wrapper hasEntered={!!enterCount} ref={forwardedRef}>
			{children}
		</Wrapper>
	);
};

type WrapperProps = {
	hasEntered: boolean;
};
const Wrapper = styled.div<WrapperProps>`
	opacity: ${({ hasEntered }) => (hasEntered ? 1 : 0)};
	transition: opacity 0.3s ease-in;
`;

const FadeInHOC = handleViewport(FadeIn);

export default FadeInHOC;
